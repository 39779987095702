
import { useBackButton, IonButtons, IonImg, useIonRouter, IonSpinner, IonButton, IonIcon, IonRange, IonInput, IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonItem, IonSelect, IonSelectOption, IonLabel } from '@ionic/vue';
import { defineComponent, reactive, onMounted, computed, toRefs, ref, watch } from 'vue';
import { useStore } from 'vuex';
import { add, remove, moon, sunny } from 'ionicons/icons'; 
import { App } from '@capacitor/app';
import { Keyboard } from '@capacitor/keyboard';

export default defineComponent({
  name: 'HomePage',
  components: {
    IonImg,
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    IonIcon,
    IonItem,
    IonButtons,
    IonRange,
    IonButton,
    IonSelect,
    IonSelectOption,
    IonLabel,
    IonSpinner,
    IonInput
  },
  setup(){
    const store = useStore();
    let password = ref('')
    let appPassError = ref(false)

    const state:any = reactive({

      isAppLocked: computed( () => {
        return store.getters.isAppLocked
      }),

      commissions: computed( () => {
          return store.getters.commissions
      }),

      terminals: computed( () => {
          return store.getters.terminals
      }),
      
      selectedeErminalByTS: computed ( () => {
          return state?.terminals && state.terminals.length && state.terminals.find( ( t:any ) => t.value === renue.terminalSelect )
        }),
    })


    const renue:any = reactive({
        income: 0,
        terminalSelect: 0,
        terminalSliderValue: 0,
        commisionSelect: 0,
        commisionSliderValue: 0,
        profitMargin: 0.25,

        commisionCalculated: computed (() => {
          const test = Math.trunc(renue.commisionSliderValue) - (Math.trunc(renue.commisionSelect * 100) );
          console.log( 'tes', test )
          return  test
        }),

        calculatedExtra: computed (() => {
          return Math.trunc(renue.terminalSliderValue) - (Math.trunc( renue.terminalSelect ) ) 
        }),

        result: computed( () => {
          if(!renue.terminalSliderValue || !renue.commisionSliderValue) return 0

           //We are working on full numbers to avoid comma numbers issues in programming :)
          const commision = renue.commisionCalculated
          
          /*
          * obrót - income
          * 1/4 - profitMargin
          * prowizja powyżej kosztu - commision
          * 1/2 licząc od minimalnej kwoty za terminal - extra
          * 
          * Zysk jest sumą:
          * "obrót" x 1/4 x "prowizja powyżej kosztu"
          * plus
          * 1/2 licząc od minimalnej kwoty za terminal.
          **/
          let result = 0
          if(commision <= 0){
            result = ( renue.calculatedExtra / 2 )
          }else{
            result = ( ( ( (renue.income * renue.profitMargin) / 100) * (commision / 100) ) ) + ( renue.calculatedExtra / 2)
          }

          if(state.selectedeErminalByTS && state.selectedeErminalByTS.extra) result += state.selectedeErminalByTS.extra


          //const result = ( ( (commision * renue.profitMargin) / 100 ) * renue.income / 100 ) 

          return !isNaN(result) ? result.toFixed(2) : 0
        }),
    })

    /*
    * Watchers
    */
    watch( () => renue.terminalSelect, (newVal) => {
      if(newVal !== renue.terminalSliderValue) renue.terminalSliderValue = newVal
    })

    watch( () => renue.commisionSelect, (newVal) => {
      if(newVal !== renue.commisionSliderValue) renue.commisionSliderValue = newVal * 100
    })
    

    /*
    * Functional
    */
    const unlockApp = async () => {
      await store.dispatch('maybeUnlockApp', password.value).then( e => {
        if( e === false ) appPassError.value = true
        if( e === true ) {
          if(appPassError.value) appPassError.value = false
          if(!state.commissions.length && !state.isAppLocked) {
            getAppData()
          }
        }
      });
    }

    const getAppData = async () => {
      await store.dispatch('getAppData');
    }

    function commisionPinFormat(value: number){
      if( isNaN(value) ) return 0

      return `${ Math.trunc( Math.round( value) ) / 100 }%`;
    }

    function terminalPinFormat(value: number){
      if(isNaN(value)) return 0

      return `${ Math.trunc( Math.round( value) ) }zł`;
    }

    /*
    * App exit
    */
    let exitLastAttempt = Date.now()

    const ionRouter = useIonRouter();

    useBackButton( -1, () => {
      if ( !ionRouter.canGoBack() ) {
        if( ( Date.now() - exitLastAttempt ) < 2000 ){
          App.exitApp();
        }else{
          exitLastAttempt = Date.now()
        }
        //minimizeApp()
      }
    });


    /*
    * Color mdoes
    */
    let darkMode = ref(false)
    function changeColorTheme(){
      
      darkMode.value = !darkMode.value;
      document.body.classList.toggle('dark', darkMode.value);
    }

    /*
    * hooks
    */
    onMounted( ()=> {
      if(!state.commissions.length && !state.isAppLocked) {
        getAppData()
      }
    })
    
    const bgColor = computed(() => {
      const color = !darkMode.value ? '#FFF' : '#18120f';
      return `--ion-background-color: ${color}`
    })

    function onKeyEnter(e:any){
      if( e && e.target ) e.target.blur()
      Keyboard.hide()
    }

    return {
      commisionPinFormat, terminalPinFormat, changeColorTheme, unlockApp, 
      bgColor, onKeyEnter,
      darkMode, password, appPassError,
      ...toRefs(state), ...toRefs(renue),
      add, remove, moon, sunny
    }
  }

});
