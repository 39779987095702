import { createStore } from 'vuex';

const store = createStore({
    state:{
        lastSync: Number(0),
        appIsLoadingFresData: false,
        noDataAccessable: false,
        commissions: Array<any>(),
        terminals: Array<any>(),
        password: String('pgs150'),
        appLocked: Boolean(true)
    },

    getters:{
        lasySync: (state) => state.lastSync,
        getPassword: (state) => state.password,
        isAppLocked: (state) => state.appLocked,
        commissions: (state) => state.commissions,
        terminals: (state) => state.terminals,
        noData: (state) => state.noDataAccessable,
        isGettingNewData: (state) => state.appIsLoadingFresData,
    },

    mutations:{
        SET_LOCK_APP(state, payload){
            state.appLocked = payload
        },

        SET_LOADING_APP(state, payload){
            state.appIsLoadingFresData = payload
        },

        SET_COMMISTIONS(state, payload){
            state.commissions = payload;
        },

        SET_TERMINALS(state, payload){
            state.terminals = payload;
        },

        SET_DATA_NOT_ACCESSABLE(state, payload){
            state.noDataAccessable = payload;
        },

        SET_SYNC_TIME(state){
            state.lastSync = new Date().getTime();
        }
    },

    actions: {
        maybeUnlockApp: async ({ commit, state }, payload) => {
            if(payload !== state.password){
                return false
            }else {
                commit('SET_LOCK_APP', false)
                return true
            }
        },
        
        getAppData: async ({ commit }) => {
            //reset old industires
            commit('SET_LOADING_APP', true)
            commit('SET_TERMINALS', [])
            commit('SET_COMMISTIONS', [])

            await fetch( './../db/api.json' ).then( res => res.json() ).then( json => { 
                if(json && json.commissions && json.terminals) {
                    commit('SET_LOADING_APP', true)
                    commit('SET_COMMISTIONS', json.commissions)
                    commit('SET_TERMINALS', json.terminals)
                    commit('SET_SYNC_TIME')
                }
                else commit('SET_DATA_NOT_ACCESSABLE', true)


            }).catch(e => {
                 console.log('app error', e)
                commit('SET_DATA_NOT_ACCESSABLE', true)

            });

            commit('SET_LOADING_APP', false)

        }
    }

})

export default store;